<template>
  <div class="page d-flex flex-column flex-md-row align-center justify-center">
    <img
      class="d-block mr-md-10 mb-5 mb-md-0"
      :src="require('@/assets/images/maintenance.svg')"
      style="max-width: 350px"
      alt="Maintenance image"
    />

    <div class="text-center text-md-left" style="padding-bottom: 80px">
      <h1 class="my-3">{{ $t("maintenance.title") }}</h1>
      <p style="max-width: 400px">{{ $t("maintenance.text") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Maintenance",
  },

  created() {
    if (!this.$env.maint) {
      this.$router.replace({ name: "app" });
    }
  },
};
</script>
